import Image from 'next/image';

import Slide from '@/public/images/slide1.png';

const LoginFooter = () => {
    return (
        <div className="hidden h-screen min-h-screen flex-col bg-sky-400 lg:flex">
            <div className="flex-1">
                <div className="relative bottom-[22.57%%] left-[40%] top-[22.56%] size-full">
                    <Image
                        alt="Slide #1"
                        src={Slide}
                        fill
                        quality={100}
                        priority
                        className="z-30 object-contain"
                        sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 100vw,
              100vw"
                    />
                </div>
            </div>
            <div className="relative h-1/4 bg-sky-600">
                <div className="absolute bottom-9 right-9">
                    <span className="text-5 font-medium leading-6 tracking-[-0.01em] text-white">
                        Work smarter. Work faster.
                    </span>
                </div>
            </div>
        </div>
    );
};

export { LoginFooter };
