import { CheckCircleFilledIcon, ErrorIcon } from '@in2event/icons';
import { useTranslation } from 'react-i18next';

/* eslint-disable no-undef */
interface MessageProps {
    text: string;
    as?: keyof JSX.IntrinsicElements;
}

const NoDataMessageText = ({ as: Component = 'span' }: Pick<MessageProps, 'as'>) => {
    const { t } = useTranslation();

    return (
        <Component className="p-4 text-sm font-semibold leading-4 text-neutral-60">
            {t('noDataAvailable', { ns: 'errors' })}
        </Component>
    );
};

const ErrorMessageText = ({ text, as: Component = 'span' }: MessageProps) => (
    <div className="relative flex items-start" role="message-text">
        <div className="mr-1 shrink-0">
            <ErrorIcon className="size-4 fill-red-600" />
        </div>
        <Component className="grow break-words text-xs font-medium text-red-600">{text}</Component>
    </div>
);

const ErrorMessageTextBox = ({ text, as: Component = 'span' }: MessageProps) => (
    <div
        className="relative my-2 flex items-start rounded-md bg-red-100 px-3 py-2.5"
        role="message-text"
    >
        <div className="mr-1 shrink-0">
            <ErrorIcon className="size-4 fill-red-600" />
        </div>
        <Component className="grow break-words text-xs font-medium text-red-600">{text}</Component>
    </div>
);

const SuccessMessageText = ({ text, as: Component = 'span' }: MessageProps) => (
    <div className="relative flex items-start" role="message-text">
        <div className="mr-1 shrink-0">
            <CheckCircleFilledIcon className="size-4 fill-green-700" />
        </div>
        <Component className="grow break-words text-xs font-medium text-green-700">
            {text}
        </Component>
    </div>
);

export { NoDataMessageText, ErrorMessageText, ErrorMessageTextBox, SuccessMessageText };
