import { LogoTextNew } from '@in2event/icons';
import Link from 'next/link';
import type { PropsWithChildren } from 'react';

import { LoginFooter } from '@/components/shared-login/login-footer';

const LoginLayout = ({ children }: PropsWithChildren) => {
    return (
        <div className="fixed inset-0 h-screen min-h-screen">
            <div className="grid grid-cols-1 lg:grid-cols-2">
                <div className="flex h-screen min-h-screen flex-col">
                    <div className="pl-4 pt-4 lg:pl-6 lg:pt-9">
                        <Link href="/" aria-label="Move back to homepage">
                            <LogoTextNew
                                viewBox="0 0 160 41"
                                className="h-9 w-30"
                                aria-hidden="true"
                            />
                        </Link>
                    </div>
                    <div className="flex flex-1 items-center justify-center overflow-y-hidden">
                        <div className="max-w-[360px] flex-1 items-center justify-center px-4">
                            {children}
                        </div>
                    </div>
                </div>
                <LoginFooter />
            </div>
        </div>
    );
};

export { LoginLayout };
